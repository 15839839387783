<template>
	<page>
		<module enable-flex>
			<m-search ref="search" @search="searchHandle">
				<m-search-item label="仓库">
					<c-select name="warehouse_id" options="PRODUCT_WAREHOUSE_LIST" ></c-select>
				</m-search-item>
				<m-search-item label="产品名称">
					<c-input name="product_name"></c-input>
				</m-search-item>
				<m-search-item label="产品编码">
					<c-input name="product_code"></c-input>
				</m-search-item>
				<m-search-item label="产品颜色">
					<c-input name="product_spec"></c-input>
				</m-search-item>
				<m-search-item label="">
					<c-checkbox-group name="is_have" value="1">
						<c-checkbox value="1">只看有库存</c-checkbox>
					</c-checkbox-group>
				</m-search-item>
			</m-search>

			<c-table ref="table" height="grow">
				<c-table-column
					label="仓库"
					width="100"
					name="warehouse_name"
				>
				</c-table-column>
				<c-table-column
					label="产品名称"
					width="300"
					name="product_name"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.id == data.id && item.data.product_id == data.product_id){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
					<template #default="{data}">
						<span>{{data.product_name}}</span>
					</template>
				</c-table-column>
				
				<c-table-column
					label="产品编号"
					width="150"
					name="product_code"
				>
				</c-table-column>
				
				<c-table-column
					label="颜色"
					width="80"
					name="product_color"
					v-if="systemConfig.system_size_enable=='ON'"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.id == data.id && item.data.product_id == data.product_id && item.data.product_color == data.product_color){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
					<template v-slot="{data}">
						<span v-if="data.product_color" >{{data.product_color}}</span>
						<span v-else>-</span>
					</template>
				</c-table-column>
				<c-table-column
					label="颜色"
					name="product_spec"
					width="60"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.id == data.id && item.data.product_id == data.product_id && item.data.product_color == data.product_color && item.data.product_spec == data.product_spec){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				<template v-for="(size, i) in sizeConfig">
					<c-table-column
						:label="String(i+1)"
						width="50"
						v-if="systemConfig.system_size_enable=='ON'"
					>
						<template #default="{data}">
							<span v-if="data[size]">{{data[size]}}</span>
							<span v-else>-</span>
						</template>
					</c-table-column>
				</template>
				
				<c-table-column
					label="库存"
					name="product_count"
					width="60"
					v-if="systemConfig.system_size_enable=='OFF'"
				>
				</c-table-column>
			</c-table>
		</module>
  </page>
</template>

<script>
import {cryptoJs} from '@deanwu/vue-component-library'
import {mapState} from 'vuex'
import {asyncMapState} from '@/store'

export default {
	name: 'm_inventory_product',
	components: {
    
	},
	data() {
		return {
			
		}
	},
	computed: {
		...mapState(['sizeConfig','systemConfig']),
		...asyncMapState(['DIC_INSTORAGE_SOURCE'])
	},
	mounted() {
		this.$refs.search.search();
		this.$bus.$on('productInventoryList.product.update', page => {
			this.$refs.table.update(page);
		});
	},
	methods: {
		//搜索
		searchHandle(data) {
			data.type = 1;
			this.$refs.table.load({
				url: '/inventory/product_list',
				data
			});
		},
  }
}
</script>