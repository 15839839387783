var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page',[_c('module',{attrs:{"enable-flex":""}},[_c('m-search',{ref:"search",on:{"search":_vm.searchHandle}},[_c('m-search-item',{attrs:{"label":"仓库"}},[_c('c-select',{attrs:{"name":"warehouse_id","options":"PRODUCT_WAREHOUSE_LIST"}})],1),_c('m-search-item',{attrs:{"label":"产品名称"}},[_c('c-input',{attrs:{"name":"product_name"}})],1),_c('m-search-item',{attrs:{"label":"产品编码"}},[_c('c-input',{attrs:{"name":"product_code"}})],1),_c('m-search-item',{attrs:{"label":"产品颜色"}},[_c('c-input',{attrs:{"name":"product_spec"}})],1),_c('m-search-item',{attrs:{"label":""}},[_c('c-checkbox-group',{attrs:{"name":"is_have","value":"1"}},[_c('c-checkbox',{attrs:{"value":"1"}},[_vm._v("只看有库存")])],1)],1)],1),_c('c-table',{ref:"table",attrs:{"height":"grow"}},[_c('c-table-column',{attrs:{"label":"仓库","width":"100","name":"warehouse_name"}}),_c('c-table-column',{attrs:{"label":"产品名称","width":"300","name":"product_name","row-span":({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.id == data.id && item.data.product_id == data.product_id){
								span_count ++;
							}
						});
						return span_count;
					}},scopedSlots:_vm._u([{key:"default",fn:function({data}){return [_c('span',[_vm._v(_vm._s(data.product_name))])]}}])}),_c('c-table-column',{attrs:{"label":"产品编号","width":"150","name":"product_code"}}),(_vm.systemConfig.system_size_enable=='ON')?_c('c-table-column',{attrs:{"label":"颜色","width":"80","name":"product_color","row-span":({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.id == data.id && item.data.product_id == data.product_id && item.data.product_color == data.product_color){
								span_count ++;
							}
						});
						return span_count;
					}},scopedSlots:_vm._u([{key:"default",fn:function({data}){return [(data.product_color)?_c('span',[_vm._v(_vm._s(data.product_color))]):_c('span',[_vm._v("-")])]}}],null,false,4237336800)}):_vm._e(),_c('c-table-column',{attrs:{"label":"颜色","name":"product_spec","width":"60","row-span":({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.id == data.id && item.data.product_id == data.product_id && item.data.product_color == data.product_color && item.data.product_spec == data.product_spec){
								span_count ++;
							}
						});
						return span_count;
					}}}),_vm._l((_vm.sizeConfig),function(size,i){return [(_vm.systemConfig.system_size_enable=='ON')?_c('c-table-column',{attrs:{"label":String(i+1),"width":"50"},scopedSlots:_vm._u([{key:"default",fn:function({data}){return [(data[size])?_c('span',[_vm._v(_vm._s(data[size]))]):_c('span',[_vm._v("-")])]}}],null,true)}):_vm._e()]}),(_vm.systemConfig.system_size_enable=='OFF')?_c('c-table-column',{attrs:{"label":"库存","name":"product_count","width":"60"}}):_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }